<template>
  <div id="app">
    <!-- <div class="bg"></div> -->
    <div class="fg">
      <transition name="slide-fade">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
.bg {
  height: 100%;
  width: 100%;
  background-image: url("@/assets/img/image4.crop.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(5px);
}

/* .fg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
} */

#app {
  /* max-width: 90vw !important; */
  /* max-height: 100vh; */
  font-family: "Roboto", sans-serif;
  background-color: black;
}

.scroll-enter-active {
  animation: scroll 0.5s;
}

.scroll-leave-active {
  animation: scroll 0.5s reverse;
}

@keyframes scroll {
  0% {
    transform: translateY(100px);
  }
  50% {
    transform: translateY(10);
  }
  100% {
    transform: translateY(0);
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-90px);
  opacity: 0;
}

.btn-dark {
  background-color: #111;
  border-color: #111;
}

.btn-dark:hover {
  background-color: #000;
  border-color: #000;
}

.btn-light {
  background-color: #fff;
  border-color: #fff;
  color: #000;
}

.btn-light:hover {
  background-color: #ccc;
  border-color: #ccc;
  color: #000;
}

.bg-gray {
  background-color: #eeeeee;
}

.bg-white {
  background-color: #fff;
}

.ico {
  width: 32px;
  height: 32px;
  margin-left: 7px;
  margin-right: 7px;
}
</style>
