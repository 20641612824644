<template>
  <div class="short-about">
    <div>See booking link for hours and services</div>
    <!-- <div>
      Open 9am - 7pm
    </div>
    <div>
      <week-circles :days="schedule"></week-circles>
    </div> -->
    <div>
      Walk-Ins accepted based on availability
    </div>
    <div>
      <a href="https://goo.gl/maps/J8imDCAsALPZfFRL9" class="address"
        >3678 West 9800 South, South Jordan, Utah 84009</a
      >
    </div>
    <div>
      <a href="tel:13852273966">1 (385) 227 3966</a>
    </div>
    <div>
      <img src="ico/Instagram-Logo.white.png" class="ico" />
      <a href="https://www.instagram.com/fasttimesbarberco/"
        >fasttimesbarberco</a
      >
    </div>
  </div>
</template>

<script>
// import WeekCircles from "./WeekCircles.vue"

export default {
  // components: { WeekCircles },
  name: "ShortAbout",
  data: () => ({
    schedule: [true, true, true, false, true, true, true]
  })
};
</script>

<style scoped>
.short-about {
  background-color: black;
  color: white;
  padding-top: 50px;
  padding-bottom: 50px;
}

.short-about > div {
  display: flex;
  flex-direction: row;
  justify-content: center;

  font-size: larger;
}

a {
  color: white;
}

.spacer {
  margin-left: 10px;
  margin-right: 10px;
}

@media screen and (max-width: 600px) {
  .address {
    max-width: 100vw;
    font-size: 4vw;
  }
}
</style>