import { render, staticRenderFns } from "./MainPage.vue?vue&type=template&id=6f2f6c89&scoped=true"
import script from "./MainPage.vue?vue&type=script&lang=js"
export * from "./MainPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_vue-template-compiler@2.7.16_webpack@5.93.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f2f6c89",
  null
  
)

export default component.exports