<template>
  <div class="about">
    <div class="long-about">
      <h1>This is Fast Times.</h1>
      <p>
        A space carefully created to bring a unique and tailored barbering
        experience to the Salt Lake Valley. No corners cut, no stone unturned,
        here at Fast Times we go above and beyond to make sure every detail is
        in check. Whether it's the esthetic of the shop, or the quality of the
        cut, you will leave feeling assured that no detail was spared. Come see
        what we are all about, and we can't wait to meet you.
      </p>
      <p>- The Fast Times Crew</p>
    </div>
    <short-about></short-about>
  </div>
</template>

<script>
import ShortAbout from "../ShortAbout.vue";
export default {
  components: { ShortAbout },
  name: "About",
};
</script>

<style scoped>
.about {
  display: flex;
  flex-direction: column;
  justify-content: center;

  text-align: center;
}

.long-about {
  background-color: white;
  color: black;
  padding-top: 70px;
  padding-bottom: 50px;
  padding-left: 30px;
  padding-right: 30px;
}

.long-about h1 {
  font-size: 48pt;
  margin-bottom: 25px;
}
</style>