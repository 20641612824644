<template>
  <div :class="{ container: !$isMobile() }">
    <div :class="{ row: !$isMobile() }">
      <div :class="{ 'col-md-12': !$isMobile() }">
        <top></top>
        <about></about>
        <!-- <pricing></pricing> -->
        <!-- <barbers></barbers> -->
        <br />
        <bottom></bottom>
      </div>
    </div>
  </div>
</template>

<script>
import Top from "../components/page_parts/Top.vue";
import About from "../components/page_parts/About.vue";
// import Pricing from "../components/page_parts/Pricing.vue";
// import Barbers from "../components/page_parts/Barbers.vue";
import Bottom from "../components/page_parts/Bottom.vue";

export default {
  name: "MainPage",
  components: { Top, About, Bottom },
};
</script>

<style scoped>
</style>